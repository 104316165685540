import React from 'react';
//import logo from './logo.svg';
//redeploy
import './App.css';
import Home from "./Home";
import Fetch from './Fetch';


function App() {
  return (
    <Home></Home>
  );
}

export default App;
